<template>
  <page-content>
    <edit-field-header
      :title="title"
      @routerBack="$router.back()"
    />

    <edit-field
      ref="edit_field"
      :is-edit="true"
      :fields="fields"
      :row-data="row_data"
      post-api="/facility/UpdateFacility"
      finish-router="facility"
      permission="Fac_Edit"
      @beforeSubmit="beforeSubmit"
    >
      <template v-slot:inputs="scope">
        <slot-inputs
          ref="slot_inputs"
          :is-edit="scope.isEdit"
          :root-item="scope.rootItem"
          :items="row_data['callBellConfig.contactEmails']"
          @updateValues="(value) => row_data['callBellConfig.contactEmails'] = value"
        />
      </template>
    </edit-field>
  </page-content>
</template>

<script>
import fields, { moduleMap, reloadFieldData } from "@/views/facility/fields";
import EditField from "@/components/EditField2";
import EditFieldHeader from "@/components/EditFieldHeader";
import common from "@/common";
import PageContent from "@/components/PageContent";
import SlotInputs from '../../components/FieldInputs';
import { flattenObject, expandObject } from '@/libs/ez-utils';

export default {
  name: "Edit",
  components: {
    PageContent,
    EditFieldHeader,
    EditField,
    SlotInputs,
    fields,
  },
  data() {
    return {
      title: common.getMenuName('facility') + ' - ' + common.getI18n('common.edit'),
      id: this.$router.currentRoute.params.id,
      fields,
      row_data: {
        selectedModuleIDs: [],
      },
      // moduleMap2: moduleMap
    }
  },
  // computed: {
  //   moduleMap() {
  //     return moduleMap
  //   }
  // },
  created() {
    reloadFieldData();
  },
  methods: {
    beforeSubmit(callback) {
      const rowData = expandObject(JSON.parse(JSON.stringify(this.$refs.edit_field.rowData)))
      if(rowData?.callBellConfig?.contactEmails) {
        rowData.callBellConfig.contactEmails = rowData.callBellConfig.contactEmails.join(';')
      }
      // if(!rowData?.selectedModuleIDs.includes(83)) { // not include "CallBell"
      if(!rowData?.selectedModuleIDs.includes(moduleMap['CallBell_Menu'])) {
        rowData.callBellConfig = null
      }
      if(rowData.contractEndDay === '') {
        rowData.contractEndDay = null
      }
      callback(true, rowData)
    }
  },
  mounted() {
    this.id = common.decrypt(this.id)
    if (this.id === undefined) {
      this.$router.push({ path: '/' + this.$route.path.split('/')[1] })
      return false
    }
    common.apiGetData('/facility/GetFacility?facilityID='+this.id).then(res => {
      const rowData = res.data.facility
      if(rowData?.callBellConfig?.contactEmails) {
        rowData.callBellConfig.contactEmails = rowData.callBellConfig.contactEmails.split(';')
      }
      const flatRowData = flattenObject(rowData)
      Object.assign(this.row_data, flatRowData)
    })
  },
}
</script>

<style scoped>

</style>
