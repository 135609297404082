import common from '@/common'
import _ from 'lodash'

let form_options = {}
form_options.company = []
form_options.menu_style = []
form_options.languages = []
form_options.modules = []
form_options.module_groups = []

let modules = []
let modules_default = []

let module_parent = {}
let moduleMap = {}

export function reloadFieldData() {

  // init Arrays
  ;[
    form_options.company,
    form_options.menu_style,
    form_options.languages,
    form_options.modules,
    form_options.module_groups,
    modules,
    modules_default,
  ].forEach(arr => arr.length = 0)

  // init Objects
  ;[
    module_parent,
    moduleMap,
  ].forEach(obj => {
    for (const [key] in obj) {
      delete obj[key]
    }
  })

  common.apiGetData('/company/GetCompanyDropDownList')
    .then(res => {
      const company_options = common.getSelectOptions(res.data.companys, 'companyName', 'companyID')
      form_options.company.push.apply(form_options.company, company_options)
    })

  common.apiGetData('/facility/GetModulesAndOtherConfigs')
    .then(res => {
      // res.data.modules.forEach(module => {
      //   if(module.code === 'CallBell_Menu_Record') {
      //     module.disabled = true
      //   }
      // })

      const menu_style_options = common.getSelectOptions(res.data.otherConfigs.menuStyle.labels, 'text', 'labelID')
      form_options.menu_style.push.apply(form_options.menu_style, menu_style_options)

      const languages_options = common.getSelectOptions(res.data.otherConfigs.languageOption.languages, 'name', 'code')
      form_options.languages.push.apply(form_options.languages, languages_options)

      modules = common.sortModules(res.data.modules)
      moduleMap = _.zipObject(modules.map(m => m.code), modules.map(m => m.moduleID))
      let modules_options = []
      let module_group_options = []
      for (const i in modules) {
        modules_options.push({
          text: modules[i].name,
          value: modules[i].moduleID,
          parent_id: modules[i].parentID,
          fixed: !modules[i].isOptional,
          // disabled: !!modules[i].disabled
        })

        if (modules[i].parentID) {
          if (module_parent[modules[i].parentID]) {
            module_parent[modules[i].parentID].push(modules[i].moduleID)
          } else {
            module_parent[modules[i].parentID] = [modules[i].moduleID]
          }
        }

        if (modules[i].isSelected) {
          modules_default.push(modules[i].moduleID)
        }

        if (modules[i].isOptional && !modules[i].parentID) {
          module_group_options.push({
            text: modules[i].name,
            value: modules[i].moduleID,
          })
        }
      }

      form_options.modules.push.apply(form_options.modules, modules_options)
      form_options.module_groups.push.apply(form_options.module_groups, module_group_options)
    })
}

export { moduleMap, modules_default }

export default [
  {
    name: 'facilityCode',
    label: 'facility.facilityCode',
    type: 'text',
    rule: 'required|max: 5',
    edit_disable: true,
    description: 'facility.code_editable'
  },
  {
    name: 'companyID',
    label: 'common.company',
    type: 'select',
    options: form_options.company,
    rule: 'required',
    edit_disable: true,
    description: 'facility.company_editable',
  },
  {
    name: 'chineseName',
    label: 'facility.chineseName',
    type: 'text',
    // rule: 'required|max: 20',
    rule: {
      required: true,
      max: 20,
      regex: /^[^,]+$/
    },
  },
  {
    name: 'englishName',
    label: 'facility.englishName',
    type: 'text',
    // rule: 'required|max: 50',
    rule: {
      required: true,
      max: 50,
      regex: /^[^,]+$/
    },
  }, {
    name: 'chineseAddress',
    label: 'facility.chineseAddress',
    type: 'text',
    rule: 'required|max: 100'
  }, {
    name: 'englishAddress',
    label: 'facility.englishAddress',
    type: 'text',
    rule: 'required|max: 150'
  },
  {
    name: 'contactPhone1',
    label: 'facility.contactPhone1',
    type: 'text',
    rule: 'max: 20|digits: 8'
  }, {
    name: 'contactPhone2',
    label: 'facility.contactPhone2',
    type: 'text',
    rule: 'max: 20|digits: 8'
  },
  {
    name: 'contactFax',
    label: 'facility.contactFax',
    type: 'text',
    rule: 'required|max: 20'
  },
  {
    name: 'contactPerson',
    label: 'facility.contactPerson',
    type: 'text',
    rule: 'required|max: 20',
  },
  {
    name: 'memberQuota',
    label: 'facility.memberQuota',
    type: 'number',
    rule: 'required|integer|between: 0, 99999',
    show_fn: () => common.checkPermission('Fac_Admin'),
  },
  {
    name: 'contractEndDay',
    label: 'facility.contractEndDay',
    type: 'date',
    // rule: 'required',
    show_fn: () => common.checkPermission('Fac_Admin'),
  },
  {
    label: 'facility.other_config',
    type: 'title',
  },
  {
    name: 'selectedMenuStyleID',
    label: 'facility.menu_style',
    type: 'select',
    rule: 'required',
    options: form_options.menu_style,
  },
  {
    name: 'selectedLanguageCode',
    label: 'facility.language',
    type: 'select',
    rule: 'required',
    options: form_options.languages
  },

  {
    type: 'column-separator'
  },

  {
    label: 'facility.module_config',
    type: 'title',
    show_fn: () => common.checkPermission('Fac_Admin'),
  },
  {
    name: 'selectedModuleIDs',
    label: 'facility.module_group',
    type: 'checkbox',
    options: form_options.module_groups,
    parent: module_parent,
    show_fn: () => common.checkPermission('Fac_Admin'),
  },
  {
    name: 'selectedModuleIDs',
    label: 'facility.available_module',
    type: 'checkbox',
    rule: 'required',
    show_fn: () => common.checkPermission('Fac_Admin'),
    options: form_options.modules,
    parent: module_parent
  },
  {
    label: 'facility.bem_config',
    type: 'title',
    show_fn: vm => vm.rowData.selectedModuleIDs.includes(moduleMap['Mon_Menu'])
  },
  {
    name: 'bemSilentModeEndTime',
    label: 'facility.bemSilentModeEndTime',
    type: 'time',
    rule: 'required',
    show_fn: vm => vm.rowData.selectedModuleIDs.includes(moduleMap['Mon_Menu'])
  },
  {
    label: 'facility.call_bell_config',
    type: 'title',
    rule: 'required',
    show_fn: vm => vm.rowData.selectedModuleIDs.includes(moduleMap['CallBell_Menu'])
  },
  {
    name: 'callBellConfig.armedTimeOutHour',
    label: 'facility.call_bell_config_dismiss_alert_interval',
    type: 'number',
    rule: 'required|integer|between: 1, 999',
    placeholder: '1~999',
    append: '小時',
    show_fn: vm => vm.rowData.selectedModuleIDs.includes(moduleMap['CallBell_Menu'])
  },
  {
    name: 'callBellConfig.lowBattery',
    label: 'facility.call_bell_config_low_battery_value',
    type: 'number',
    rule: 'required|integer|between: 1, 255',
    placeholder: '1~255',
    // append: '%',
    show_fn: vm => vm.rowData.selectedModuleIDs.includes(moduleMap['CallBell_Menu'])
  },
  {
    name: 'callBellConfig.lostTimeOutHour',
    label: 'facility.call_bell_config_disconnect_interval',
    type: 'number',
    rule: 'required|integer|between: 1, 999',
    placeholder: '1~999',
    append: '小時',
    show_fn: vm => vm.rowData.selectedModuleIDs.includes(moduleMap['CallBell_Menu'])
  },
  {
    name: 'callBellConfig.autoEmailIntervalHour',
    label: 'facility.call_bell_config_email_interval',
    type: 'number',
    rule: 'required|integer|between: 0, 999',
    placeholder: '0~999' + common.getI18n('facility.call_bell_config_email_interval_tips'),
    append: '小時',
    show_fn: vm => vm.rowData.selectedModuleIDs.includes(moduleMap['CallBell_Menu'])
  },
  {
    name: 'callBellConfig.contactEmails',
    label: 'facility.call_bell_config_email_addresses',
    rule: 'required',
    type: 'slot',
    validate_in_slot: true,
    slot: 'inputs',
    slot_config: {
      name: 'email-addresses',
      label: 'staff.contactEmail',
      rule: 'required|max: 50|email',
      type: 'text',
      min_count: 1,
      max_count: 5,
      check_duplicate: true,
    },
    show_fn: vm => vm.rowData.selectedModuleIDs.includes(moduleMap['CallBell_Menu'])
  },
]
